.scrollable-table {

        overflow: auto;
        table-layout: auto !important;

}

.textfielddni div input,
#textDni {
        text-transform: uppercase !important;
}

.MuiButton-root:focus {
        outline: none !important;
}

.nohoverbtn:hover {
        background-color: transparent !important;
}

::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
}

::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.logincards .MuiCardHeader-action button {
        margin-top: 10px;
}

.logincards:hover,
.logincards:hover>.logincards button {
        border-color: #009EFD;

        .MuiCardHeader-title {
                color: #009EFD;

        }
        .MuiSvgIcon-root {
                color: #009EFD;

        }
}

@font-face {
        font-family: 'DINPro';
        src: url('../fonts/DINPro.ttf') format('truetype');
}
@font-face {
        font-family: 'DINPro-Black';
        src: url('../fonts/DINPro-Black.ttf') format('truetype');
}
@font-face {
        font-family: 'DINPro-Bold';
        src: url('../fonts/DINPro-Bold.ttf') format('truetype');
}
@font-face {
        font-family: 'DINPro-Medium';
        src: url('../fonts/DINPro-Medium.ttf') format('truetype');
}
.formcontrolrememberme span{
        font-family: 'DINPro-Medium';
        color:#aaaaaa;
}